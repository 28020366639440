<template lang="pug">
.main-wrapper.transacoes-listar
		header.main-wrapper-header
			.p-grid.p-align-center
				.p-col-12.ta-right
					h1.text-header.text-secondary MedClub / Caixa / <b>Transações</b>

		Dialog.dialogVisualizar(v-if='selected' header='Visualizar transação' :visible.sync='dialogVisualizar' :modal='true' @hide='selected = null')
			p <b>ID:</b> {{ selected.id }}
			p <b>Pagante:</b> {{ selected.nm_pagante }}
			p <b>CPF:</b>  {{ selected.nr_cpf_pagante_f }}
			p <b>Caixa:</b> {{ selected.nm_caixa }}
			p <b>Unidade:</b> {{ selected.nm_unidade_medclub }}
			p <b>Data:</b> {{ selected.dt_criado_f }}
			p <b>Valor:</b> {{ formatPrice(selected.nr_valor) }}

		Dialog.dialogNotas(v-if='notas' header='Notas da transação' :visible.sync='dialogNotas' :modal='true' @hide='notas = null')
			.mb-4
				p <b>ID da transação:</b> {{ notas.id }}

			Card.my-2(v-for='item in notas.notas' :key='item.id')
				template(slot='content')
					.p-grid.p-fluid
						.p-col-6
							span <b>ID:</b> {{ item.id }}
						.p-col-6
							span <b>Status:</b> {{ item.status }}
						.p-col-12(v-if='item.aq_nfse')
							Button(@click='abrirArquivo(item.aq_nfse)' label='Abrir arquivo' icon='jam jam-printer')

		Dialog.dialogVisualizarGuias(header='Guias eletrônicas' :modal='true' :visible.sync='dialogVisualizarGuias')
			.ta-center.my-4(v-if='waitingGuias' style='margin:80px 0;')
				ProgressSpinner(strokeWidth='2')
			div(v-else)
				div(v-for='guiasPaciente in guias')
					div(v-for='guiasEstabelecimento in guiasPaciente')
						.mb-2(v-if='guiasEstabelecimento.length')
							p(style='margin:0') <b>Paciente:</b> {{ guiasEstabelecimento[0].nm_paciente }}
							p(style='margin:0') <b>Estabelecimento:</b> {{ guiasEstabelecimento[0].nm_estabelecimento }}
							DataTable.mt-2(:value='guiasEstabelecimento')
								Column(field='id' header='Cód.' headerStyle='width:12%' bodyStyle='text-align:center')
								Column(header='Procedimento' headerStyle='width:53%')
									template(#body='props')
										span {{props.data.nm_procedimento}};
										span.d-block(v-if='[5,6,8].includes(props.data.cd_guia_situacao)' style='font-size:10px;')
											b Executado em:
											span {{props.data.dt_execucao_f}}
											span(v-if='props.data.nm_usuario_edi') ({{props.data.nm_usuario_edi}})
											span ;
										span.d-block(v-if='props.data.cd_especialista' style='font-size:0.8rem;')
											b Especialista:
											span {{props.data.cd_especialista.nm_especialista}} ({{props.data.cd_especialista.nm_conselho}} {{props.data.cd_especialista.nr_conselho}} - {{props.data.cd_especialista.cd_uf_sigla}}).
										span.d-block(v-if='props.data.nm_especialidade' style='font-size:0.8rem;')
											b Especialidade:
											span {{props.data.nm_especialidade}}
								Column(field='nr_valortotal_f' header='Valor' headerStyle='width:20%' bodyStyle='text-align:center')
								Column(field='cd_guia_situacao' header='Situação' headerStyle='width:15%' bodyStyle='text-align:center')
									template(#body='props')
										span(v-if='[5,6,8].includes(props.data.cd_guia_situacao)' v-tooltip.top='"Executado"') <i style='color: #64BA02; font-size: 2.5em;' class='jam jam-check'></i>
										span(v-if='props.data.cd_guia_situacao == 7' v-tooltip.top='"Cancelado"') <i style='color: #DF1818; font-size: 2em;' class='jam jam-close'></i>
										span(v-if='props.data.cd_guia_situacao == 4' v-tooltip.top='"Agendado"') <i style='color: #F5CB11; font-size: 1.5em;' class='jam jam-clock'></i>

		Dialog.dialogEnviarEmail(header='Enviar comprovante por email' :modal='true' :visible.sync='dialogEnviarEmail' @hide='transacaoEmail={}; invalidEmail = false')
			.ta-center.my-4(v-if='waitingEnviar' style='margin:80px 0;')
				ProgressSpinner(strokeWidth='2')
			div(v-else)
				//div(v-for='guiasPaciente in guias')
				//	div(v-for='guiasEstabelecimento in guiasPaciente')
				//		.mb-2(v-if='guiasEstabelecimento.length')
				p(style='margin:0') <b>Paciente:</b> {{ transacaoEmail.nm_pagante }}
				label.form-label E-mail:
				.p-inputgroup
					InputText( @keyup.enter.native='checkEmail()' v-model='transacaoEmail.ds_email_pagante' style='width:80%' )
					Button(label='Enviar' icon='jam jam-paper-plane' @click='checkEmail()')
				.feedback--errors(v-if='invalidEmail')
					.form-message--error(v-if="invalidEmail") Email Inválido.

		Panel.mb-2(header='Filtros' :toggleable='true' :collapsed='windowInnerWidth < 576')
			.p-grid.p-fluid.p-align-end

				.p-col-12.p-md-3
					label.form-label Caixa:
					Dropdown( v-model='filters.cd_caixa' :options='options.caixas'
						dataKey='id' optionLabel='nm_caixa' optionValue='id' placeholder='- TODOS -' @change='applyFilters()' )

				.p-col-12.p-md-3
					label.form-label Cód. Guia:
					.p-inputgroup
						InputText( @keyup.enter.native='applyFilters()' v-model='filters.id'
							@keyup='checkEmptyField("id")' )
						Button(icon='jam jam-search' @click='applyFilters()')

				.p-col-12.p-md-6(:class="{ 'form-group--error': invalidNmPagante, 'shake-animation': shakeError }")
					label.form-label Nome:
					.p-inputgroup
						InputText( @keyup.enter.native='applyFilters()' v-model='filters.nm_pagante'
							@input='(val) => !val && applyFilters()')
						Button(icon='jam jam-search' @click='applyFilters()')
					.feedback--errors(v-if='invalidNmPagante')
						.form-message--error(v-if="invalidNmPagante") Deve ter pelo menos 5 caracteres.

				.p-col-12.p-md-3
					label.form-label CPF:
					.p-inputgroup
						InputMask(@keydown.enter='applyFilters()' v-model='filters.nr_cpf'
							@keyup='checkEmptyField("nr_cpf")' mask='999.999.999-99')
						Button(icon='jam jam-search' @click='applyFilters()')

				.p-col-12.p-md-3
					label.form-label Nota fiscal:
					Dropdown( v-model='filters.nf' :options='options.nf'
						dataKey='value' optionLabel='label' optionValue='value' placeholder='- TODOS -' @change='applyFilters()' )

				.p-col-12.p-md-3
					label.form-label Indicador:
					.p-inputgroup
						InputText( @keyup.enter.native='applyFilters()' v-model='filters.nm_indicador'
							@keyup='checkEmptyField("nm_indicador")' )
						Button(icon='jam jam-search' @click='applyFilters()')
				.p-col-12.p-md-3
					label.form-label Nome do Estabelecimento:
					ProgressBar(v-if="options.estabelecimento_list.length == 0" mode="indeterminate")
					div(v-else)
						MultiSelect(
							:options='options.estabelecimento_list'
							@change='applyFilters()'
							placeholder='TODOS'
							optionLabel='text'
							optionValue='value'
							dataKey='value'
							:filter='true'
							v-model='filters.cd_estabelecimentos'
						)
				.p-col-6.p-md-3
					label.form-label Data inicial:
					.p-inputgroup
						Calendar( v-model='filters.dt_inicio' dateFormat="dd/mm/yy" :locale="ptbr" :maxDate='filters.dt_fim || new Date()'
							:manualInput='false' :touchUI='windowInnerWidth < 576' @input='applyFilters()' )
						Button.p-button-danger(icon='jam jam-rubber' v-tooltip.top="'Limpar'" @click='filters.dt_inicio = null; applyFilters()')

				.p-col-6.p-md-3
					label.form-label Data final:
					.p-inputgroup
						Calendar( v-model='filters.dt_fim' dateFormat="dd/mm/yy" :locale="ptbr" :minDate='filters.dt_inicio' :maxDate='new Date()'
							:manualInput='false' :touchUI='windowInnerWidth < 576' @input='applyFilters()' )
						Button.p-button-danger(icon='jam jam-rubber' v-tooltip.top="'Limpar'" @click='filters.dt_fim = null; applyFilters()')
				.p-col-12.p-md-3
					label.form-label Tipo de guias:
					Dropdown( v-model='filters.ie_tipo_guia' :options='options.ie_tipo_guia'
						dataKey='id' optionLabel='label' optionValue='value' placeholder='- TODOS -' @change='applyFilters()' )
				.p-col-12.p-md-3
					label.form-label Tipo de Nota Fiscal:
					SelectButton(
						v-model="nf_automatica"
						:options="options.opt_nf_automatica"
						optionLabel="text"
						optionValue="value"
						@input='applyFilters()'
					)
		ProgressBar(v-if='waiting' mode="indeterminate")
		div(v-else-if='list.length == 0')
			p.ta-center.text-secondary(style='margin-top: 40px;') Nenhum registro encontrado.
		div(v-else)
			DataView.dataview.my-2(:value="list" layout="grid")
				template(#grid="props")
					.p-col-12.p-md-3(style="padding: .5em" :class="{ disabled: !props.data.ie_status }")
						Panel.panel-list.ta-center(:header="props.data.nm_pagante" style='position: relative')
							.ta-left
								p(v-if='props.data.id') <b>Código da guia:</b> {{ props.data.id }}
								p <b>Pagante:</b>
									span {{ props.data.nm_pagante }}&nbsp;
									span(v-if='props.data.nr_cpf_pagante') ({{ props.data.nr_cpf_pagante_f }})
								p <b>Data:</b> {{ props.data.dt_criado_f }}
								p <b>Valor:</b> {{ formatPrice(props.data.nr_valor) }}
							.ta-right.p-buttonset.mt-3
								Button.p-button-raised.p-button-rounded(icon="jam jam-eye"
									@click="selected=props.data; dialogVisualizar = true")
			.p-grid.p-col-12.pr-0
				.p-col-4.mt-1
					InputSwitch.mr-2(v-model='mostrarSelecionados' :disabled='!selecionados.length')
					label.label-mostrar-selecionados Mostrar somente selecionados
				.p-col-5.ta-center.mt-1.pr-0
						p.text-soma.ma-0(v-if='somaTotal') <b>Total Filtrado:</b> {{ formatPrice(somaTotal) }}
						p.text-soma.ma-0.ml-2 <b>Selecionados:</b> {{ selecionados.length }}
						p.text-soma.ma-0.ml-2 <b>Soma Selecionados:</b> {{ formatPrice(sum) }}
						a.link-limpar.ml-2.mr-0.mt-1(v-if='sum > 0' @click='limparSelecao()') Limpar seleção
				.p-col-3.ta-right.mt-1.pr-0(v-if='usuarioNivel >= 5')
					Button(v-if='showEmitirTodos' label='Emitir NF em todos' icon='jam jam-files' @click='emitirNFTodos()')
					div(v-else) Emitidos: <b>{{ emitirTodosCounter }} / {{ list.length }}</b>
			Panel.datatable(header='Lista de transações')
				Paginator.mb-1(:rows='paginadorDinamico.rows' :first='paginadorDinamico.first' :totalRecords='paginadorDinamico.total' @page="paginadorDinamico.onPage")
				DataTable(ref='datatableTransacoes' :value="listaDinamica"
						:selection.sync="selecionados"
						selectionMode="multiple"
						:metaKeySelection="false"
						dataKey="id"
						:rowClass="rowClass"
					)
					Column(headerStyle='width: 5%;' bodyStyle='text-align: center')
						template(#header='{data}')
							Checkbox(v-if='mostrarSelecionados ? selecionados.length : list.length' v-model='selecionarTodos' binary)
						template(#body='{data}')
							Checkbox(v-model='selecionados' :value='data' :id='data.id' @click.stop)
					Column(headerStyle='width: 8%;' field='id' header='Cód. Guia' bodyStyle='text-align: center')
					Column(headerStyle='width: 8%;' field='ds_hash' header='Senha' bodyStyle='text-align: center')
					Column(headerStyle='width: 13%;' field='nm_caixa' header='Caixa')
					Column(headerStyle='width: 30%;' header='Pagante')
						template(#body='props')
							span {{ props.data.nm_pagante }}&nbsp;
							span(v-if='props.data.nr_cpf_pagante') ({{ props.data.nr_cpf_pagante_f }})
					Column(headerStyle='width: 10%;' bodyStyle='text-align: center;' field='dt_criado_f' header='Data')
					Column(headerStyle='width: 10%;' bodyStyle='text-align: left;' header='Valor')
						template(#body='props')
							span(:class="{ 'valor-negativo': props.data.nr_valor < 0 }") {{ formatPrice(props.data.nr_valor) }}
					Column(headerStyle='width: 10%;' bodyStyle='text-align: left;' header='Taxa')
						template(#body='props')
							span(:class="{ 'valor-negativo': props.data.nr_valor_medclub < 0 }") {{ formatPrice(props.data.nr_valor_medclub) }}
					Column(headerStyle='width: 18%;' bodyStyle='text-align: center;' header='Ações')
						template(#body='props')
							ProgressSpinner.waitingNF(v-if='waitingNF === props.data.id' strokeWidth='6')
							div(v-else)
								Button.p-button-raised.p-button-rounded.mr-1.mb-1(
									v-if="! props.data.ie_orcamento && usuarioNivel >= 5"
									v-tooltip.top="`Nota Fiscal${props.data.nf_automatica ? ' (Automática)' : ''}`"
									icon='jam jam-paper-plane'
									@click='emitirNF(props.data.id)'
									:disabled='!props.data.ie_permitir_nf'
									:class="{ 'p-button-warning': props.data.ie_emitido_nf }"
									:style="{ color: props.data.nf_automatica ? 'white' : '', borderColor: props.data.nf_automatica ? '#ae34eb' : '', backgroundColor: props.data.nf_automatica ? '#ae34eb' : '' }"
								)
								Button.p-button-raised.p-button-rounded.p-button-success.mr-1.mb-1(v-tooltip.top="`Ver Guias`"
									icon='jam jam-ticket' @click='listarGuias(props.data.id)')
								Button.p-button-raised.p-button-rounded.mr-1.mb-1(v-tooltip.top="`Visualizar`"
									icon='pi pi-external-link' @click='visualizar(props.data.id)')
								Button.p-button-raised.p-button-rounded.p-button-warning.mr-1.mb-1(v-tooltip.top="'Enviar comprovante'"
									icon='jam jam-envelope' @click='enviarGuia(props.data)' :disabled='props.data.ie_guia_medclub_empresa')
				Paginator.mb-1(:rows='paginadorDinamico.rows' :first='paginadorDinamico.first' :totalRecords='paginadorDinamico.total' @page="paginadorDinamico.onPage")
</template>

<style lang="scss">
	.transacoes-listar {
		.waitingNF {
			width: 20px;
			height: auto;
			margin-right: 18px;
			vertical-align: middle;
		}
		.dataview {
			@media all and (min-width: 577px) {
				display: none;
			}
		}
		.datatable {
			@media all and (max-width: 576px) {
				display: none;
			}
			.cell {
				padding: 16px 0;
				text-align: center;
				&.ex  {  background-color: #e4f8e1; }
				&.a  { background-color: #faf3dd; }
			}
			tr.p-highlight {
				background-color: rgb(234, 232, 232) !important;
				color: #333333 !important;
			}
		}
		.dialogVisualizar, .dialogNotas {
			width: 92%;
			max-width: 500px;
			p {
				margin: 4px 0;
			}
		}
		.dialogNotas { max-width: 400px; }
		.dialogVisualizarGuias {
			width: 92%;
			max-width: 900px;
			max-height: 500px;
			overflow-y: auto;
		}
		.dialogEnviarEmail {
			width: 92%;
			max-width: 640px;
			max-height: 500px;
			overflow-y: auto;
		}
		.link-limpar {
			text-decoration: underline;
			font-size: 12px;
			cursor: pointer;
			font-weight: 700;
			margin-right: 10px;
			display: inline-block;
			margin-bottom: 6px;
		}
		.text-soma {
			margin-bottom: 4px;
			margin-right: 10px;
			display: inline-block;
			background-color: #faf3dd;
			box-shadow: 0 1px 2px #ddd;
			padding: 6px 10px;
		}
		.label-mostrar-selecionados {
			vertical-align: super;
			font-size: 12px;
			font-weight: 550;
		}
	}
	.p-tooltip {
		max-width: none;
	}
</style>

<script>
	import ProgressBar from 'primevue/progressbar'
	import DataView from 'primevue/dataview'
	import Panel from 'primevue/panel'
	import Paginator from 'primevue/paginator'
	import DataTable from 'primevue/datatable'
	import Column from 'primevue/column'
	import InputText from 'primevue/inputtext'
	import Calendar from 'primevue/calendar'
	import InputMask from 'primevue/inputmask'
	import Button from 'primevue/button'
	import Dialog from 'primevue/dialog'
	import ProgressSpinner from 'primevue/progressspinner'
	import Dropdown from 'primevue/dropdown'
	import Tooltip from 'primevue/tooltip'
	import Card from 'primevue/card'
	import Checkbox from 'primevue/checkbox'
	import InputSwitch from 'primevue/inputswitch'
	import SelectButton from 'primevue/selectbutton';
	import CustomMultiSelect from '../CustomComponents/CustomMultiSelect.vue';
	import MultiSelect from 'primevue/multiselect'
	import moment from 'moment'
	import { Caixas, GuiaEletronica, DominioValor, Estabelecimento} from '../../middleware'
	import { pCalendarLocale_ptbr, validateEmail } from './../../utils'
	import wsConfigs from './../../middleware/configs'
	import axios from "axios"
	const _ = require('lodash')

	export default {
		beforeRouteEnter (to, from, next) {
			next(vm => {
				let routeName = vm.$route.path.split('/')[1]
				if (from.path.split('/')[1] === routeName && vm.$store.state.filters)
					vm.filters = vm.$store.state.filters
				vm.$store.commit('clearFilters')
			})
		},
		beforeRouteLeave (to, from, next) {
			let routeName = this.$route.path.split('/')[1]
			if (to.path.split('/')[1] === routeName)
				if (this.filters) this.$store.commit('setFilters', this.filters)
			next()
		},
		created () {
			this.waiting = true
			Caixas.findAll().then(response => {
				if (response.status == 200) {
					this.options.caixas = response.data
					this.options.caixas.unshift({ id: null, nm_caixa: '- TODOS -' })
					this.applyFilters()

					let usuario = JSON.parse(localStorage.getItem('usuario'))
					this.usuarioNivel = usuario.nivel
				}
			})
			DominioValor.findAll({ds_mnemonico: 'TIPO_GUIA_ELETRONICA'}).then(response => {
				if(response.status == 200) {
					this.options.ie_tipo_guia.push({ value: null, label: '- TODOS -' })
					response.data.TIPO_GUIA_ELETRONICA?.valores.forEach((item) => {
					this.options.ie_tipo_guia.push({
						value: item.ie_valor,
						label: item.ds_valor,
						});
					});
				}
			})
			this.getEstabelecimentos()
		},
		components: { ProgressBar, DataView, Panel, Paginator, DataTable, InputMask, Card, CustomMultiSelect, MultiSelect,
			Column, Button, Dialog, Tooltip, ProgressSpinner, InputText, Calendar, Dropdown, Checkbox, InputSwitch, SelectButton },
		directives: { tooltip: Tooltip },
		watch: {
			'selecionados': function(val) {
				if (!val.length)
					this.mostrarSelecionados = false
				else if (this.mostrarSelecionados)
					this.paginadorDinamico['total'] = val.length
			},
			mostrarSelecionados: function() {
				this.atualizaPaginacao()
			},
		},
		computed: {
			selecionarTodos:  {
				get() {
					return this.mostrarSelecionados || this.selecionados.length && _.every(this.list, (v) => _.find(this.selecionados, { id: v.id }))
				},
				set(val) {
					if(val)
						this.selecionados = _.unionBy(this.list, this.selecionados, 'id')
					else
						this.selecionados = this.mostrarSelecionados ? [] : _.xorBy(this.selecionados, this.list, 'id')
				}
			},
			sum: function() {
				return _.sumBy(this.selecionados, 'nr_valor')
			},
			listaDinamica: function() {
				if (this.mostrarSelecionados) {
					const reagrupa = _.groupBy(this.selecionados, (v) => _.toInteger((_.indexOf(this.selecionados, v))/this.paginadorDinamico.rows))
					return reagrupa[this.paginadorDinamico.page] 
				} else
					return this.list
			}
		},
		data () {
			return {
				list: [],
				ptbr: pCalendarLocale_ptbr,
				windowInnerWidth: window.innerWidth,
				waiting: false,
				waitingNF: 0,
				waitingGuias: false,
				waitingEnviar: false,
				waitingEstabelecimento: false,
				filters: {
					id: null,
					nm_pagante: '',
					nm_indicador: '',
                    ie_situacao: 'FI',
					nr_cpf: '',
					cd_caixa: null,
					dt_inicio: null,
					dt_fim: null,
					nf: null,
					ie_tipo_guia: null,
					usuarioNivel:0,
					cd_estabelecimentos: []
				},
				paginator: {
                    page: this.$route.query.pg || 1,
					per_page: wsConfigs.paginator_perPage,
					count: 0
				},
				paginadorDinamico: {
					rows: wsConfigs.paginator_perPage,
					first: 0,
					total: 0,
					page: this.$route.query.pg || 1,
					onPage: null
				},
				options: {
					caixas: [],
					estabelecimento_list: [],
					nf: [
						{ value: null, label: '- TODOS -' },
						{ value: true, label: 'Emitidos' },
						{ value: false, label: 'Não emitidos' }
					],
					ie_tipo_guia: [],
					opt_nf_automatica: [
						{text: 'Todas', value: false},
						{text: 'Automáticas', value: true}
					],
				},
				transacaoEmail:{},
				sendEmailId: null,
				selected: null,
				selecionados: [],
				mostrarSelecionados: false,
				somaTotal: 0,
				notas: null,
				guias: [],
				supervisao: false,
				dialogVisualizar: false,
				dialogNotas: false,
				dialogVisualizarGuias: false,
				dialogEnviarEmail: false,
				showEmitirTodos: true,
				emitirTodosCounter: 0,
				invalidNmPagante: false,
				invalidEmail:false,
				shakeError: false,
				cancelToken: null,
				params: {},
				nf_automatica: false,
				getListDebounce: _.debounce(function (params, getFunction) {
					getFunction(params)
				}, 500, { leading: true })
			}
		},
		methods: {
			getList (params) {
				if (this.cancelToken) this.cancelToken.cancel()
				this.cancelToken = axios.CancelToken.source()

				this.waiting = true
				Caixas.findAllTransacoes(params, this.cancelToken.token).then(response => {
					if (!response) return
					if (response.status === 200) {
						this.paginator.count = response.data.count
						this.paginadorDinamico.total = response.data.count
						response.data.results.forEach(transacao => {
							transacao.dt_criado_f = moment(transacao.dt_criado).format('DD/MM/YYYY')
							if (transacao.nr_cpf_pagante) transacao.nr_cpf_pagante_f = transacao.nr_cpf_pagante.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
						})
						this.list = response.data.results
						this.somaTotal = response.data.total
					}

					this.atualizaPaginacao()
					this.waiting = false
					this.cancelToken = null

					setTimeout(() => {
						if (this.selected) {

							let checked = false
							this.selected.forEach(item => {
								let el = document.querySelector(`._row_${ item.id }`)
								if (el && !checked) {
									checked = true
									el.click()
								}
							})
							if (checked) this.selected.pop()
						}
					})
				})
			},
			isSupervisao() {
				let rotaAtual = this.$route.path.split('/')[1]
				return rotaAtual === "transacoes-financeiras"
			},
			checkEmptyField (field) {
				if (! this.filters[field].length) this.applyFilters()
			},
			checkEmail () {
				if (validateEmail(this.transacaoEmail.ds_email_pagante)) {
					this.enviarEmail()
				}
				else{
					this.invalidEmail = true;
				}
			},
			checkNmPagante () {
				this.invalidNmPagante = this.filters.nm_pagante && this.filters.nm_pagante.length < 5
			},
			applyFilters (page) {
				this.checkNmPagante()
				if (this.invalidNmPagante) {
					this.shakeError = true
					setTimeout(() => this.shakeError = false, 300)
					return
				}

				this.paginator.page = page || 1

				const p = this.$route.query.pg || page || 1

				this.paginadorDinamico.page = p
				this.paginator.page = p

				let params = { paginacao: true, page: this.paginadorDinamico.page, per_page: this.paginadorDinamico.rows, nf_automatica: false }
				Object.keys(this.filters).forEach((key) => {
					if (key === 'nr_cpf') {
						params.nr_cpf = this.filters[key].replace(/(\.|-|_)/g, '')
					} else if (key === 'nf') {
						if (this.filters[key] !== null) params.nf = this.filters[key]
					else if(key == 'ie_tipo_guia')
						if (this.filters[key] !== null) params.ie_tipo_guia = this.filters[key]
					} else if (key.substr(0, 3) == 'dt_') {
						if (this.filters[key]) params[key] = moment(this.filters[key]).format('YYYY-MM-DD')
					} else if (this.filters[key]) params[key] = this.filters[key]
				})

				if (this.nf_automatica){
					params['nf_automatica'] = true;
				}
				// Fake loading quando os filtros forem os mesmos
				if (_.isEqual(this.params, params)) {
					if (!this.waiting) {
						this.waiting = true
						setTimeout(() => this.waiting = false, 300)
					}
				} else {
					this.params = Object.assign({}, params)
					this.waiting = true
					this.getListDebounce(params, this.getList)
				}
			},
			formatPrice (val) {
				return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(val)
			},
			onPage (ev) {
				this.paginadorDinamico.page = ev.page + 1
				this.applyFilters(this.paginadorDinamico.page)
			},
			visualizar (id) {
				if(this.isSupervisao()) this.$router.push(`/transacoes-financeiras/visualizar/${ id }/`)
				else this.$router.push(`/transacoes-financeiras-operacional/visualizar/${ id }/`)
			},
			listarGuias (id) {
				// this.$router.push(`/transacoes-financeiras/listar-guias/${ id }/`)
				this.dialogVisualizarGuias = true
				this.waitingGuias = true
				this.guias = []
				GuiaEletronica.consultarGuia(id).then(response => {
					this.waitingGuias = false
					if ([200, 201].includes(response.status)) {
						response.data.forEach(guia => {
							if (! this.guias[guia.cd_paciente.id]) this.guias[guia.cd_paciente.id] = {}
							if (! this.guias[guia.cd_paciente.id][guia.cd_estabelecimento.id]) this.guias[guia.cd_paciente.id][guia.cd_estabelecimento.id] = []
							this.guias[guia.cd_paciente.id][guia.cd_estabelecimento.id].push({
								id: guia.id,
								nm_procedimento: guia.guiaeletronicaitem_set.length ? guia.guiaeletronicaitem_set[0].nm_procedimento : '',
								nr_valortotal: parseFloat(guia.nr_valortotal),
								nr_valortotal_f: this.formatPrice(parseFloat(guia.nr_valortotal)),
								dt_execucao_f: moment(guia.dt_execucao).format('DD/MM/YYYY HH:mm:ss'),
								cd_guia_situacao: guia.cd_guia_situacao,
								nm_paciente: guia.cd_paciente.nm_pessoa_fisica,
								nm_usuario_edi: guia.cd_paciente.nm_usuario_edi,
								nm_estabelecimento: guia.cd_estabelecimento.nm_fantasia,
								cd_especialista: guia.cd_especialista,
								nm_especialidade: guia.nm_especialidade
							})
						})
					}
				})
			},
			enviarGuia (transacao){
				this.transacaoEmail = Object.assign({}, transacao);
				this.dialogEnviarEmail = true;
			},
			enviarEmail(){
				this.waitingEnviar = true;
				Caixas.enviarEmail(this.transacaoEmail.id, {ds_email: this.transacaoEmail.ds_email_pagante}).then(response =>{
					this.waitingEnviar = false;
					if(response.status == 200){
						this.dialogEnviarEmail = false;
						this.$toast.success('Email enviado', { duration: 4000 })
					}
					if(response.status == 400){
						if (response.data.detail) {
							if (typeof response.data.detail == 'string')
								this.$toast.error(response.data.detail, { duration: 4000 })
							else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }))
						}
					}
				})
			},
			emitirNF (id) {
				this.waitingNF = id
				return Caixas.emitirNF(id, true).then(response => {
					this.waitingNF = 0
					if (([200, 201, 204]).includes(response.status)) {
						let lista_notas = response.data
						if (lista_notas.nfs != undefined)
							if ( lista_notas.nfs.length > 0 ) {
								this.notas = {
									id: id,
									notas: lista_notas.nfs
								}
								if (this.showEmitirTodos) this.dialogNotas = true
							}
						this.$toast.info(`Nota fiscal atualizada: EMITIDA`, { duration: 3000 })
						this.list.find(item => item.id === id).ie_emitido_nf = true
					} else if (response.data.detail) {
                        if (typeof response.data.detail == 'string')
                            this.$toast.error(response.data.detail, { duration: 3000 })
                        else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }))
					}
					return 0
				})
			},
			emitirNFTodos () {
				this.emitirTodosCounter = 0
				this.showEmitirTodos = false
				const emitir = idx => {
					if (idx === this.list.length) {
						this.showEmitirTodos = true
						return 0
					}
					this.emitirNF(this.list[idx].id).then(() => {
						window.setTimeout(() => { this.emitirTodosCounter++; emitir(++idx) })
					})
				}
				emitir(0)
			},
			rowClass (a) {
				return `_row_${ a.id }`
			},
			limparSelecao () {
				this.selecionados = []
			},
			abrirArquivo(arquivo){
				window.open(arquivo, "_blank")
			},
			atualizaPaginacao () {
				this.paginadorDinamico['page'] = this.mostrarSelecionados 
											? 0 
											: this.paginator.page  || 1
				;

				[	
					this.paginadorDinamico['first'],
					this.paginadorDinamico['total'],
					this.paginadorDinamico['onPage'],
				] = this.mostrarSelecionados ? [
					0,
					this.selecionados.length,
					({ page })  => {
						this.paginadorDinamico.page = page;
						this.paginadorDinamico.first = this.paginadorDinamico.page * this.paginadorDinamico.rows;
					},
				] : [
					((this.paginadorDinamico.page - 1) * this.paginadorDinamico.rows),
					this.paginator.count,
					this.onPage,
				]
			},
			getEstabelecimentos(){
				this.waitingEstabelecimento = true
				Estabelecimento.findAllClean({order: 'nm_fantasia', ie_atende_medclub_empresa: true }).then(response => {
					this.waitingEstabelecimento = false
					if (response.status === 200) {
						response.data.forEach(e => {
							this.options.estabelecimento_list.push({ text: e.nm_fantasia, value: e.id })
						})
						// this.options.estabelecimento_list.unshift({ text: 'TODOS', value: null })
					}
				})
			},
		}
	}
</script>
